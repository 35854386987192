import React from 'react'

import Layout from '../components/layout'
import BlogRoll from '../components/blogRoll'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  latestAtLux: {
    paddingTop: `6rem`,
    paddingBottom: `6rem`,
    color: `rgb(16,16,17)`
  },
  blogRollWrapper: {
    paddingTop: `6rem`,
    paddingBottom: `6rem`,
    backgroundColor: `#fff`
  },
}))

const LatestAtLux = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "lightning_bolt.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Latest at Lux" description="Performing arts blog informing you of the latest news at LUX" />
      <BackgroundImage fluid={data.file.childImageSharp.fluid} className={classes.backgroundImage} style={{backgroundSize: 'cover !important', backgroundRepeat: `no-repeat !important`, backgroundPosition: `center !important`, backgroundColor: `#f7cd56`}}>
        <Container maxWidth='lg' className={classes.latestAtLux}>
          <Typography variant="h2" component="h2" align="center">
            Latest at LUX
          </Typography>
          <Typography variant="h6" component="h6" align="center">
            A performing arts blog
          </Typography>
        </Container>
      </BackgroundImage>
      <Box className={classes.blogRollWrapper} component="section">
        <Container>
          <BlogRoll />
        </Container>
      </Box>
    </Layout>
  )
}

export default LatestAtLux